<template>
  <section class="selectForGetUser">
    <div class="selectForGetUser__containerInput">
      <iconic class="selectForGetUser__iconSearch" name="my_search" />
      <input
        class="selectForGetUser__input selectForGetUser__input--search"
        type="text"
        placeholder="Buscar"
        v-model="searchValue"
        @input="searchUsers"
      />
    </div>
    <div class="selectForGetUser__dropdown" v-show="showDropdown">
      <div
        class="selectForGetUser__option"
        v-for="user in users"
        :key="user._id"
        :disabled="isCreated(user)"
        @click="!isCreated(user) && selectOption(user)"
      >
        <span>{{ user.name }}</span>
        <span class="selectForGetUser__inactive" v-if="isCreated(user)">Usuario creado</span>
      </div>
      <div class="spinner" v-if="isLoading"></div>
      <div class="getMore"></div>
    </div>
  </section>
</template>

<script>
export default {
  props: ["searchRole", "selectUser"],
  data() {
    return {
      searchValue: "",
      page: 0,
      showDropdown: false,
      hasMore: false,
      isLoading: false,
      timeOutSearch: null,
      users: [],
    };
  },
  methods: {
    isCreated(user) {
      return !!user?.userIdOtherCollection || !!user?.idUserCollection;
    },
    async getUsers() {
      this.isLoading = true;

      const { data } = await this.$axios.get(`/employees?search=${this.searchValue}&role=${this.searchRole}&page=${this.page}&limit=10`);
      const { hasMore, users } = data;

      this.hasMore = hasMore;
      this.isLoading = false;

      return users;
    },
    async getMoreUsers() {
      if (!this.hasMore || this.isLoading) {
        return;
      }

      this.page++;
      const users = await this.getUsers();

      this.users = [].concat(this.users, users);
    },
    searchUsers() {
      clearTimeout(this.timeOutSearch);

      this.timeOutSearch = setTimeout(async () => {
        this.page = 0;
        this.showDropdown = !!this.searchValue;
        this.users = !this.searchValue ? [] : await this.getUsers();
      }, 400);
    },
    selectOption(option) {
      this.searchValue = option.name;
      this.showDropdown = false;
      this.users = [];
      this.selectUser(option);
    },
    async onIntersection(entries) {
      if (entries[0].isIntersecting && !this.isLoading) {
        await this.getMoreUsers();
      }
    },
    async observeLastElement(el) {
      if (el) {
        const options = {
          root: null,
          rootMargin: "0px",
          threshold: 1.0,
        };
        const observer = new IntersectionObserver(this.onIntersection, options);
        observer.observe(el);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const lastElement = document.querySelector(".getMore");
      this.observeLastElement(lastElement);
    });
  },
};
</script>

<style lang="scss">
.selectForGetUser {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  position: relative;
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 5px;
    &--search {
      padding: 0 0 0 30px;
    }
  }
  &__containerInput {
    position: relative;
    width: 100%;
  }
  &__iconSearch {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 5px;
    font-size: 20px;
  }
  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 150px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    z-index: 2;
    overflow-y: auto;
  }
  &__option {
    @include Flex(row, flex-start);
    gap: 10px;
    padding: 8px;
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  }
  &__inactive {
    font-size: 0.8em;
    font-weight: bold;
  }
}
</style>
