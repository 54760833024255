<template>
  <section class="personalData">
    <h2 class="personalData__title">Datos personales</h2>
    <SelectForGetUser :searchRole="role" :selectUser="autoFill" v-if="!isEditUser" />
    <div class="personalData__containerCol">
      <select
        class="personalData__input"
        v-model="$store.state.user.dataUser.documentType"
        ref="documentIdSelect"
        @change="validation('documentId', 'documentIdSelect', 'formatSelection', 'Tipo de Documento')"
      >
        <option value="">Tipo identificación</option>
        <option v-for="document in documentOptions" :value="document.value" :key="document.value">{{ document.name }}</option>
      </select>
      <input
        class="personalData__input"
        type="text"
        placeholder="Identificación *"
        v-model="$store.state.user.dataUser.documentId"
        :disabled="!isEditUser"
        ref="documentIdForm"
        @blur="
          $store.state.user.dataUser.documentType === 'passport'
            ? noneSpecialCharacters('documentId', 'documentIdForm', 'Numero de Documento')
            : validation('documentId', 'documentIdForm', 'formatNumber', 'Numero de Documento')
        "
      />
    </div>
    <span ref="documentId" class="personalData__error"></span>
    <div class="personalData__contentInput">
      <label for="name">Nombre *</label>
      <input
        class="personalData__input"
        type="text"
        id="name"
        ref="nameForm"
        placeholder="Nombre"
        :disabled="!isEditUser"
        v-model="$store.state.user.dataUser.name"
        @blur="validation('name', 'nameForm', 'formatString', 'Nombre')"
      />
    </div>
    <span ref="name" class="personalData__error"></span>
    <div class="personalData__contentInput">
      <label for="lastname">Apellido *</label>
      <input
        class="personalData__input"
        type="text"
        id="lastname"
        ref="lastnameForm"
        placeholder="Apellido"
        :disabled="!isEditUser"
        v-model="$store.state.user.dataUser.lastname"
        @blur="validation('lastname', 'lastnameForm', 'formatString', 'Apellido')"
      />
    </div>
    <span ref="lastname" class="personalData__error"></span>
    <div class="personalData__contentInput">
      <label for="email">Correo *</label>
      <input
        class="personalData__input"
        id="email"
        ref="emailForm"
        type="email"
        placeholder="Correo electrónico"
        :disabled="!isEditUser"
        v-model="$store.state.user.dataUser.email"
        @blur="validation('email', 'emailForm', 'formatEmail')"
      />
    </div>
    <span ref="email" class="personalData__error"></span>
    <div class="personalData__contentInput">
      <label for="phone">Celular *</label>
      <input
        class="personalData__input"
        type="text"
        id="phone"
        placeholder="Celular"
        v-model="$store.state.user.dataUser.phone"
        ref="phoneInput"
        @blur="validation('phone', 'phoneInput', 'formatNumber', 'celular')"
      />
    </div>
    <span ref="phone" class="personalData__error"></span>
  </section>
</template>

<script>
import SelectForGetUser from "./SelectForGetUser.vue";
import { formatNumber, formatString, formatEmail, formatSelection } from "@/js/validation.js";
export default {
  components: { SelectForGetUser },
  computed: {
    user() {
      return this.$store.state.user.dataUser;
    },
    isEditUser() {
      return !!this.user?._id;
    },
    role() {
      return this.user.role;
    },
    documentOptions() {
      const { documentType } = this.$global.dictionary;
      return Object.entries(documentType).map(([key, value]) => ({ name: value, value: key }));
    },
  },
  methods: {
    autoFill(userSelected) {
      const user = this.$store.state.user.dataUser;
      const specialKeys = {
        name: ({ lastname = "", lastName = "", name = "" }) => (lastname || lastName ? name : name.split(" ")[0]),
        email: ({ emailWork = "", email = "" }) => emailWork || email,
        documentId: ({ documentId = "" }) => documentId + "",
        city: ({ city = "" }) => this.getCity(city),
        workShiftName: ({ workShift = {} }) => workShift?.name || "",
        lastname: ({ lastname = "", lastName = "", name = "" }) => (lastname || lastName ? lastname || lastName : name.split(" ")[1] || ""),
        companies: ({ siteRegister }) => siteRegister ? [siteRegister] : []
      };

      userSelected.userIdOtherCollection = userSelected._id;
      Object.entries(user).forEach(([key, val]) => {
        const valInUserSelected = specialKeys.hasOwnProperty(key) ? specialKeys[key](userSelected) : userSelected?.[key] || "";
        const value = valInUserSelected || val;

        user[key] = value;
      });

      this.verifyNext();
    },
    getCity(city) {
      if (this.$cities.includes(city)) {
        return city;
      }

      const cities = this.$global.dictionary.cities;
      const [key] = Object.entries(cities).find(([key, val]) => val === city);

      return key || "";
    },
    updateStyles(reference, referenceLocal, response) {
      reference.style.display = response ? "none" : "block";
      referenceLocal.style["border-color"] = response ? "#d8d8d8" : "#bd0909";
    },
    validation(ref, refLocal, method, text, data) {
      const reference = this.$refs[ref];
      const referenceLocal = this.$refs[refLocal];
      const dataToSet = data ?? event?.srcElement ?? { value: "" };

      const functions = {
        formatEmail: (...params) => formatEmail(...params),
        formatString: (...params) => formatString(...params),
        formatNumber: (...params) => formatNumber(...params),
        formatSelection: (...params) => formatSelection(...params),
      };

      const response = functions[method]?.(reference, text, dataToSet);

      this.updateStyles(reference, referenceLocal, response);
      return response;
    },
    noneSpecialCharacters(ref, refLocal, info) {
      const reference = this.$refs[ref];
      const referenceLocal = this.$refs[refLocal];
      const re = /^[a-zA-Z0-9]*$/;
      let innerText = "";

      if (this.user.documentId === "") {
        innerText = `Ingrese ${info}`;
      }
      if (!re.test(this.user.documentId)) {
        innerText = `${info} inválido`;
      }
      const response = !innerText;

      reference.innerText = innerText;
      this.updateStyles(reference, referenceLocal, response);

      return response;
    },
    verifyNext() {
      const { documentType, documentId, name, lastname, email, phone } = this.user;

      const documentIdSelect = this.validation("documentId", "documentIdSelect", "formatSelection", "Tipo de Documento", { value: documentType });
      const documentIdForm =
        documentType === "passport"
          ? this.noneSpecialCharacters("documentId", "documentIdForm", "Numero de Documento")
          : this.validation("documentId", "documentIdForm", "formatNumber", "Numero de Documento", { value: documentId });
      const nameForm = this.validation("name", "nameForm", "formatString", "Nombre", { value: name });
      const lastnameForm = this.validation("lastname", "lastnameForm", "formatString", "Apellido", { value: lastname });
      const emailForm = this.validation("email", "emailForm", "formatEmail", "", { value: email });
      const phoneInput = this.validation("phone", "phoneInput", "formatNumber", "celular", { value: phone });

      return documentIdSelect && documentIdForm && nameForm && lastnameForm && emailForm && phoneInput;
    },
  },
  mounted() {
    if (this.isEditUser) {
      this.verifyNext();
    }
  },
};
</script>

<style lang="scss">
.personalData {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
  }
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 5px;
    &--search {
      padding: 0 0 0 30px;
    }
  }
  &__inputSearch {
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 0 0 0 30px;
    width: 100%;
  }
  &__contentInput,
  &__containerInput {
    position: relative;
    width: 100%;
  }
  &__containerCol {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    width: 100%;
    gap: 15px;
  }
  &__iconSearch {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 5px;
    font-size: 20px;
  }
  &__error {
    display: none;
    margin-top: -10px;
    font-weight: 600;
    font-size: 0.8em;
    color: $primary-color;
  }
}
</style>
